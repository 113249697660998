<template>
  <v-dialog v-model="visible"
            persistent
            width="auto"
            max-width="450">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-alert v-if="alert.show"
                 :color="alert.type">
          {{ alert.content }}
        </v-alert>
        {{ content }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn class="grey--text darken-1"
               text
               @click.native="cancelevent()">
          {{ button.cancel.text }}
        </v-btn>
        <v-btn :class="button.ok.class"
               @click.native="action()">
          {{ button.ok.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    actionName: {
      type: String,
      default: null,
      required: false,
    },
    content: {
      type: String,
      default: '',
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    button: {
      type: Object,
      default: () => {},
      required: true,
    },
    alert: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      visible: true,
    };
  },

  methods: {
    /**
     * Callback method when the action button is clicked.
     * Will emit "action" event.
     */
    action() {
      this.$emit('action');
    },

    /**
     * Callback method when the cancel button is clicked.
     * Will emit "cancelevent" event.
     */
    cancelevent() {
      this.$emit('cancelevent');
    },
  },
};
</script>
